import Vue from 'vue';
import VueRouter from 'vue-router';

import ImageView from '../../pages/tools/imageview';
import UrlDecode from '../../pages/tools/urldecode';
import ColorConvert from '../../pages/tools/colorconvert';
import ColorCollection from '../../pages/tools/colorcollection';
import TextJoin from '../../pages/tools/textjoin';
import TextMatch from '../../pages/tools/textmatch';
import TemplateEdit from '../../pages/www/template/edit';
import ArticleList from '../../pages/studying/article/list';
import ArticleView from '../../pages/studying/article/view';
import ArticleEdit from '../../pages/studying/article/edit';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'ImageView',
    component: ImageView,
    meta: {
      title: '图片预览',
    },
  },
  {
    path: '/page/image-view',
    name: 'ImageView',
    component: ImageView,
    meta: {
      title: '图片预览',
    },
  },
  {
    path: '/page/url-decode-encode',
    name: 'UrlDecode',
    component: UrlDecode,
    meta: {
      title: 'Url Decode&Encode',
    },
  },
  {
    path: '/page/color-convert',
    name: 'ColorConvert',
    component: ColorConvert,
    meta: {
      title: '颜色值转换',
    },
  },
  {
    path: '/page/color-collection',
    name: 'ColorCollection',
    component: ColorCollection,
    meta: {
      title: '常用颜色',
    },
  },
  {
    path: '/page/text-join',
    name: 'TextJoin',
    component: TextJoin,
    meta: {
      title: '逗号分隔连接字符串',
    },
  },
  {
    path: '/page/text-match',
    name: 'TextMatch',
    component: TextMatch,
    meta: {
      title: '在线正则表达式提取文本',
    },
  },
  {
    path: '/page/template/edit',
    name: 'TemplateEdit',
    component: TemplateEdit,
    meta: {
      title: '模板',
    },
  },
  {
    path: '/page/studying/article/list',
    name: 'ArticleList',
    component: ArticleList,
    meta: {
      title: '文章列表',
      keepAlive: false,
    },
  },
  {
    path: '/page/studying/article/view/:article_id',
    name: 'ArticleView',
    component: ArticleView,
    meta: {
      title: '文章详情',
      keepAlive: false,
    },
  },
  {
    path: '/page/studying/article/edit',
    name: 'ArticleEdit',
    component: ArticleEdit,
    meta: {
      title: '新增文章',
      keepAlive: false,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
