<template>
  <div class="template-edit">
    <Form :model="template" label-position="top" :rules="ruleValidate">
      <FormItem label="描述" prop="name">
        <Input v-model="template.name" placeholder=""/>
      </FormItem>
      <FormItem label="缩写">
        <Input v-model="template.abbr" placeholder=""/>
      </FormItem>
      <h4>参数</h4>
      <div class="box">
        <table class="items">
          <thead>
          <tr>
            <th>参数名</th>
            <th>描述</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(param, index) in template.params" :key="'param_'+index">
            <td>
              <FormItem label="" prop="param.name">
                <Input v-model="param.name" placeholder=""/>
              </FormItem>
            </td>
            <td>
              <FormItem label="" prop="param.desc">
                <Input v-model="param.desc" placeholder="" style="width: 300px;"/>
              </FormItem>
            </td>
            <td>
              <div class="row-operation-btn">
                <button type="button" class="operation-btn" @click="param_delete(index)">
                  <i class="fas fa-trash"></i>
                </button>
                <button type="button" class="operation-btn operation-btn-up"
                        @click="param_move_up(index)">
                  <i class="fas fa-arrow-up"></i>
                </button>
                <button type="button" class="operation-btn operation-btn-down"
                        @click="param_move_down(index)">
                  <i class="fas fa-arrow-down"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="box-operation-btn">
          <button type="button" class="operation-btn" @click="param_add">
            <i class="fas fa-plus"></i>
          </button>
        </div>

      </div>

      <h4>代码模板</h4>
      <div class="box">

        <div class="box-items">
          <div v-for="(code_item,index) in template.code_items" :key="'code_item_' + index">
            <h4>{{ index + 1 }}.{{ code_item.name }}</h4>
            <div class="box" style="position: relative;">

              <div class="right-corner-operation-btn">
                <button type="button" class="operation-btn" @click="code_item_delete(index)">
                  <i class="fas fa-trash"></i>
                </button>
                <button type="button" class="operation-btn operation-btn-up"
                        @click="code_item_move_up(index)">
                  <i class="fas fa-arrow-up"></i>
                </button>
                <button type="button" class="operation-btn operation-btn-down"
                        @click="code_item_move_down(index)">
                  <i class="fas fa-arrow-down"></i>
                </button>
              </div>

              <FormItem label="描述" prop="code_item.name">
                <Input v-model="code_item.name" placeholder=""/>
              </FormItem>

              <FormItem label="文件路径" prop="code_item.file">
                <Input v-model="code_item.file" placeholder=""/>
              </FormItem>

              <FormItem label="代码模板" prop="code_item.code">
                <Input type="textarea" rows="8" v-model="code_item.code" placeholder=""/>
              </FormItem>

            </div>

          </div>
        </div>

        <div class="box-operation-btn">
          <button type="button" class="operation-btn" @click="code_item_add">
            <i class="fas fa-plus"></i>
          </button>
        </div>

      </div>

      <FormItem>
        <Button type="primary" @click="save()">保存</Button>
      </FormItem>

    </Form>

  </div>
</template>

<script>

import api from '@/service/api_biu';

export default {
  name: 'TemplateEdit',
  data() {
    return {
      mode: '', // new or edit
      project_id: '',
      template: {
        name: '',
        abbr: '',
        params: [
          {
            name: '',
            desc: '',
          },
        ],
        code_items: [
          {
            name: '',
            file: '',
            code: '',
          },
        ],
      },
      ruleValidate: {
        name: [
          {required: true, trigger: 'blur'},
        ],
      },
    }
  },
  async mounted() {
    let template_id = this.$route.params.id;
    if (template_id) {
      let response = await api.get_template(template_id);
      if (response.code === 0) {
        this.mode = 'edit';
        this.template = response.data;
      }
    } else {
      this.mode = 'new';
      this.project_id = jstool.getParam('project_id');
    }
  },
  methods: {
    param_add() {
      this.template.params.push({
        name: '',
        desc: '',
      });
    },

    param_delete(index) {
      this.template.params.splice(index, 1);
    },

    param_move_up(index) {
      const items = this.template.params.splice(index, 1);
      this.template.params.splice(index - 1, 0, items[0]);
    },

    param_move_down(index) {
      const items = this.template.params.splice(index, 1);
      this.template.params.splice(index + 1, 0, items[0]);
    },

    code_item_delete(index) {
      this.template.code_items.splice(index, 1);
    },

    code_item_move_up(index) {
      const items = this.template.code_items.splice(index, 1);
      this.template.code_items.splice(index - 1, 0, items[0]);
    },

    code_item_move_down(index) {
      const items = this.template.code_items.splice(index, 1);
      this.template.code_items.splice(index + 1, 0, items[0]);
    },

    code_item_add() {
      this.template.code_items.push({
        name: '',
        file: '',
        code: '',
      });
    },

    async save() {
      let res = null;
      if (this.mode === 'new') {
        this.template.project_id = this.project_id;
        res = await api.add_template(this.template);
      } else {
        res = await api.update_template(this.template);
      }
      if (res && res.code === 0) {
        this.$router.push(`/template/list?project_id=${this.template.project_id}`);
      }
    },
  },
}
</script>

<style scoped lang="scss">

.template-edit {

  padding: 20px 50px;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  width: 850px;

  .box {
    border: #c5c8ce 1px solid;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }

  table.items {
    th {
      text-align: left;
      padding: 4px;
    }

    td {

    }

    tbody {

      &>:first-child {
        .operation-btn-up {
          display: none;
        }
      }

      &>:last-child {
        .operation-btn-down {
          display: none;
        }
      }
    }

    .row-operation-btn {
      opacity: 0;
    }

    &:hover {
      .row-operation-btn {
        opacity: 1;
        animation: fadein 1s ease;
      }
    }

    .ivu-form-item {
      margin-bottom: 0;
    }
  }

  .operation-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 5px;
  }

  .row-operation-btn {
    padding-left: 5px;
  }

  .right-corner-operation-btn {
    position: absolute;
    right: 20px;
    top: 7px;
  }

  .box-operation-btn {
    padding-left: 2px;
  }

  .box-items {

    &>:first-child {
      .operation-btn-up {
        display: none;
      }
    }

    &>:last-child {
      .operation-btn-down {
        display: none;
      }
    }
  }

}

</style>
