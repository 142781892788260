<template>
  <div id="app">
    <div>
      <page-header></page-header>
      <main>
        <keep-alive>
          <router-view
            v-if="
              $route.meta.keepAlive ||
                typeof $route.meta.keepAlive === 'undefined'
            "
          ></router-view>
        </keep-alive>
        <router-view v-if="$route.meta.keepAlive === false"></router-view>
      </main>
      <page-footer></page-footer>
    </div>
  </div>
</template>

<script>
  import PageHeader from '../../components/tools/pageheader';
  import PageFooter from '../../components/common/pagefooter';
  import 'element-ui/lib/theme-chalk/index.css';

  export default {
    name: 'app',
    components: {
      PageHeader,
      PageFooter,
    },

    computed: {},
  };
</script>

<style lang="scss">
  main {
    padding: 40px 20px 20px 20px;
    min-height: 800px;
    background: #ffffff;
  }
</style>
