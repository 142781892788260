<template>
  <div class="article-list">
    <article-stat ref="articleStat"></article-stat>

    <Button @click="$router.push('/page/studying/article/edit')">
      新增
    </Button>
    <ul>
      <li v-for="(article, index) in articleList" :key="index">
        <auto-link :href="`/page/studying/article/view/${article.id}`">
          {{ article.name }}
        </auto-link>

        <Poptip
          confirm
          title="Are you sure delete this article?"
          @on-ok="deleteArticle(article.id)"
        >
          <Icon type="md-close-circle" class="remove" />
        </Poptip>
      </li>
    </ul>
  </div>
</template>

<script>
  import api from '@/service/api_biu';
  import AutoLink from '../../../../components/common/autolink/AutoLink';
  import ArticleStat from '../stat/ArticleStat';

  export default {
    name: 'ArticleList',
    components: {AutoLink, ArticleStat},
    data() {
      return {
        articleList: null,
      };
    },
    mounted() {
      this.loadArticle();
    },
    methods: {
      async loadArticle() {
        let response = await api.get_article_list();
        if (response.code === 0) {
          this.articleList = response.data;
        }
      },
      async deleteArticle(articleId) {
        const res = await api.delete_article(articleId);
        if (res.code === 0) {
          await this.loadArticle();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .article-list {
    padding: 20px 120px;

    ul {
      margin: 15px;

      li {
        padding: 5px 0;

        .remove {
          visibility: hidden;
          color: #de837f;
          font-size: 16px;
        }

        &:hover {
          .remove {
            visibility: visible;
          }
        }
      }
    }
  }
</style>
