import Vue from 'vue';
import App from './App.vue';
import router from './router_tools';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

import ElementUI from 'element-ui';

import fnCommon from '../../assets/js/common';
window.jstool = Object.assign({}, fnCommon);

import './mixin';

Vue.use(ViewUI);
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  // 添加mounted，不然不会执行预编译
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
