import http from './http';
import request from './request';

const server = process.env.VUE_APP_API_BIU_SERVER;

export default {
  async exchange_user_info(code) {
    let url = server + '/exchange-user-info';
    let res = await http.postParams(url, {code});
    return res;
  },

  async logout() {
    let url = server + '/logout';
    let res = await http.post(url);
    return res;
  },

  async get_template_list(project_id) {
    let url = server + '/template-list';
    let res = await http.get(url, {project_id});
    return res;
  },

  async get_template(id) {
    let url = server + '/template';
    let res = await http.get(url, {id});
    return res;
  },

  async add_template(template) {
    let url = server + '/template';
    let res = await http.post(url, template);
    return res;
  },

  async update_template(template) {
    let url = server + '/template';
    let res = await http.put(url, template);
    return res;
  },

  async get_project_list() {
    let url = server + '/project-list';
    let res = await http.get(url);
    return res;
  },

  async get_project(id) {
    let url = server + '/project';
    let res = await http.get(url, {id});
    return res;
  },

  async add_project(project) {
    let url = server + '/project';
    let res = await http.post(url, project);
    return res;
  },

  async update_project(project) {
    let url = server + '/project';
    let res = await http.put(url, project);
    return res;
  },

  async get_article_list() {
    let url = server + '/article-list';
    let res = await http.get(url);
    return res;
  },

  async get_article(article_id) {
    let url = server + `/article/${article_id}`;
    let res = await http.get(url);
    return res;
  },

  async update_article(data) {
    let url = server + `/article`;
    let res = await http.put(url, data);
    return res;
  },

  async insert_article(data) {
    let url = server + `/article`;
    let res = await http.post(url, data);
    return res;
  },

  async delete_article(article_id) {
    let url = server + `/article/${article_id}`;
    let res = await http.delParams(url);
    return res;
  },

  async split_sentence(data) {
    let url = server + `/sentence/split`;
    let res = await http.put(url, data);
    return res;
  },

  async update_sentence(data) {
    let url = server + `/sentence`;
    let res = await http.put(url, data);
    return res;
  },

  async insert_word(data) {
    let url = server + `/word`;
    let res = await http.post(url, data);
    return res;
  },

  async update_word(data) {
    let url = server + `/word`;
    let res = await http.put(url, data);
    return res;
  },

  async get_word_list(article_id, status) {
    let url = server + `/word-list?article_id=${article_id}&status=${status}`;
    let res = await http.get(url);
    return res;
  },

  async delete_word(word_id) {
    let url = server + `/word/${word_id}`;
    let res = await http.delParams(url);
    return res;
  },

  async get_study_stat(article_id) {
    let url = server + `/study-stat?article_id=${article_id}`;
    let res = await http.get(url);
    return res;
  },

  async translate(content) {
    const params = [
      'client=gtx',
      `sl=en`,
      `tl=zh-cn`,
      'hl=en-US',
      'dt=t',
      'dt=bd',
      'dj=1',
      'source=input',
      'tk=29979.29979',
      `q=${content}`,
    ].join('&');

    let res = await request({
      url: `https://translate.googleapis.com/translate_a/single?${params}`,
      method: 'get',
    });

    return res.data;
  },
};
