<template>
  <a :href="href" @click="onClick">
    <slot></slot>
  </a>
</template>

<script>
  export default {
    name: 'AutoLink',

    props: {
      href: String,
      text: String,
    },

    methods: {
      onClick(e) {
        // eslint-disable-next-line no-empty
        if (e.ctrlKey || e.button === 1) {
        } else {
          // 没有按下ctrl 阻止a标签跳转并且使用$router切换页面
          e.preventDefault();
          e.stopPropagation();
          this.$router.push(this.href);
        }
      },
    },
  };
</script>

<style scoped></style>
