<template>
  <div class="color-collection">
    <div class="container">
      <ul class="text">
        <li>文字颜色</li>
        <li v-for="(item) in color_json.text"
            :key="item" @click="selectTextColor(item)"
            :style="textStyle(item)">{{ itemText(item) }}
        </li>
      </ul>

      <div>
        <div class="preview" :style="previewStyle" v-html="preview_text">
        </div>
        <div class="desc">
          文字颜色：<span class="color" @click="copyTextToClipboard(text_color)">{{text_color}}</span>
        </div>
        <div class="desc">
          背景颜色：<span class="color" @click="copyTextToClipboard(background_color)">{{background_color}}</span>
        </div>
      </div>

      <ul class="text">
        <li>背景色</li>
        <li v-for="(item) in color_json.background"
            :key="item" @click="selectBackgroundColor(item)"
            :style="backgroundStyle(item)">{{ itemText(item) }}
        </li>
      </ul>

    </div>

  </div>
</template>

<script>

import {COLOR_COLLECTION} from '/public/color_schema'

export default {
  name: 'ColorCollection',
  data() {
    return {
      color_json: COLOR_COLLECTION,
      background_color: '#f8f8f9',
      text_color: '#494949',
      text_name: '',
      background_name: '',
    }
  },
  methods: {
    textStyle(item) {
      const arr = item.split(',');
      return {
        color: arr[1],
        background: '#dcdee2',
      }
    },

    backgroundStyle(item) {
      const arr = item.split(',');
      return {
        color: '#494949',
        background: arr[1],
      }
    },

    itemText(item) {
      const arr = item.split(',');
      return arr[0];
    },

    selectTextColor(item) {
      const arr = item.split(',');
      this.text_color = arr[1];
      this.text_name = arr[0];
    },

    selectBackgroundColor(item) {
      const arr = item.split(',');
      this.background_color = arr[1];
      this.background_name = arr[0]
    },
  },
  computed: {
    previewStyle() {
      return {
        background: this.background_color,
        color: this.text_color,
      }
    },

    preview_text() {
      // let text1 = this.text_name ? 'color:' + this.text_name : '';
      // let text2 = this.background_name ? 'background-color:' + this.background_name : '';
      // let br = !!text1 && !!text2 ? '<br/>' : '';
      // return text1 + br + text2;
      return '天高云淡，望断南飞雁';
    },
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">

.color-collection {
  margin-top: 20px;
  padding: 20px;

  .container {
    display: flex;
  }

  .desc {
    padding: 5px 0px;
    text-align: center;
    .color {
      color: #409eff;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul.text {
    li {
      width: 200px;
      padding: 10px 30px;
      text-align: center;
      margin: 10px;
      list-style: none;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 5px;
    }
  }

  .preview {
    margin-top: 61px;
    margin-bottom: 20px;
    width: 300px;
    text-align: center;
    padding: 40px;
  }

}

</style>
