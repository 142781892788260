export const COLOR_COLLECTION = {
  "background": [
    "大海蓝,#2d8cf0",
    "天真蓝,#2db7f5",
    "苹果绿,#19be6b",
    "桔子黄,#ff9900",
    "中国红,#ed4014",
    "夜的黑,#17233d",
    "黑灰灰,#515a6e",
    "白灰灰,#c5c8ce",
    "灰白白,#f8f8f9"
  ],
  "text": [
    "石墨,#494949",
    "天蓝,#409eff",
    "浅灰,#c0c4cc",
    "灰色,#657180",
    "白色,#ffffff",
    "警告红,#ed4014"
  ]
}
