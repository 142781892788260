import axios from 'axios/index';
import realJsonp from 'jsonp';
import Qs from 'qs';

// 请求超时时间
axios.defaults.timeout = 100000;

// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },

  error => {
    // 如果有 response
    if (error.response) {
      let errorStr = JSON.stringify(error.response);
      console.error(errorStr);

      let data;
      // 如果有 response.data.code 说明是业务代码返回的结果，直接使用
      if (
        error.response.data &&
        typeof error.response.data.code !== 'undefined'
      ) {
        data = error.response.data;
      } else if (error.response.data) {
        // 如果没有 response.data.code， 只有 response.data，则将整个 data 作为 message
        data = {};
        data.message = JSON.stringify(error.response.data);
        data.code = -1;
        data.error = 1;
      }

      // 如果没有获取到 data，则将整个 error 作为 message
      if (!data) {
        data = {};
        data.message = JSON.stringify(error);
        data.code = -1;
        data.error = 1;
      }

      // 如果 message 太长，只保留前 200 个字符，其它的打在控制台
      if (data.message && data.message.length > 200) {
        console.error(data.message);
        data.message = data.message.substring(0, 200);
      }

      return Promise.reject(data);
    } else {
      // 如果没有 response，可能网络错误或超时
      let errorStr = JSON.stringify(error);
      console.error(errorStr);

      let data = {};
      if (error.message) {
        data.message = error.message;
        !!error.status &&
          (data.message = data.message + ', status:' + error.status);
      } else {
        data.message = errorStr;
      }

      if (data.message && data.message.length > 200) {
        console.error(data.message);
        data.message = data.message.substring(0, 200);
      }

      data.code = -1;
      data.error = 1;

      return Promise.reject(data);
    }
  }
);

export default {
  jsonp(url, callback) {
    return new Promise(function(resolve, reject) {
      realJsonp(url, {name: callback}, function(err, data) {
        if (!err) {
          resolve(data);
        } else {
          reject(err);
        }
      });
    });
  },

  async get(url, params, headers) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
          headers: {
            token: localStorage.getItem('token'),
            Authorization: localStorage.getItem('authorization'),
            ...headers,
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err.message);
        });
    });
  },

  async post(url, obj, headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, obj, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            token: localStorage.getItem('token'),
            Authorization: localStorage.getItem('authorization'),
            ...headers,
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err.message);
        });
    });
  },

  async postParams(url, params, headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, Qs.stringify(params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            token: localStorage.getItem('token'),
            Authorization: localStorage.getItem('authorization'),
            ...headers,
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err.message);
        });
    });
  },

  async put(url, obj, headers) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, obj, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            token: localStorage.getItem('token'),
            Authorization: localStorage.getItem('authorization'),
            ...headers,
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err.message);
        });
    });
  },

  async putParams(url, params, headers) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, Qs.stringify(params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            token: localStorage.getItem('token'),
            Authorization: localStorage.getItem('authorization'),
            ...headers,
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err.message);
        });
    });
  },

  async delParams(url, params, headers) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          params: params,
          headers: {
            token: localStorage.getItem('token'),
            Authorization: localStorage.getItem('authorization'),
            ...headers,
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err.message);
        });
    });
  },
};
