<template>
  <div class="article-edit">
    <div>
      <Input placeholder="name" v-model="article_name" />
    </div>
    <div>
      <Input
        placeholder="content"
        type="textarea"
        :rows="25"
        v-model="article_content"
      ></Input>
    </div>
    <div>
      <Input placeholder="source" v-model="media_path" />
    </div>
    <Button @click="save">Save</Button>
  </div>
</template>

<script>
  import api from '@/service/api_biu';

  export default {
    name: 'ArticleEdit',
    data() {
      return {
        article_name: '',
        article_content: '',
        media_path: '',
      };
    },
    async mounted() {},
    methods: {
      async save() {
        if (!this.article_name || !this.article_content) {
          return;
        }
        const res = await api.insert_article({
          name: this.article_name,
          content: this.article_content,
          media_path: this.media_path,
        });
        if (res.code === 0) {
          this.$router.push(
            `/page/studying/article/view/${res.data.article_id}`
          );
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .article-edit {
    padding: 20px 50px;
    div {
      margin-bottom: 10px;
    }
  }
</style>
