<template>
  <div style="padding: 5px; position: fixed; background: #eeeeee; width: 100%;">
    <router-link to="/page/image-view">图片预览</router-link>
    | <router-link to="/page/url-decode-encode">Url Decode&Encode</router-link>
    | <router-link to="/page/color-convert">颜色值转换</router-link>
    | <router-link to="/page/color-collection">常用颜色</router-link>
    | <router-link to="/page/text-join">逗号分隔连接字符串</router-link>
    | <router-link to="/page/text-match">正则表达式提取文本</router-link>
  </div>

</template>

<script>
export default {
  name: 'PageHeader',
}
</script>

<style scoped lang="scss">

</style>
