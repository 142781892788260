<template>
  <div class="text-join">
    <textarea
      rows="10"
      v-model="source"
      ref="source_input"
      placeholder="粘贴文本到此处"
      style="width:100%; margin-bottom: 5px; padding: 10px;"
    />
    <input
      v-model="regStr"
      @input="matchText"
      placeholder="正则匹配表达式"
      title="可用括号标识要提取的部分, 如：name:(.*), 则可提取姓名部分"
      style="width: 100%; padding:8px; margin: 0 0 10px 0;"
    />
    <input
      v-model="groupIndex"
      @input="matchText"
      placeholder="要提取第几个括号的内容"
      style="width: 180px; padding:8px; margin-right: 5px;"
    />
    <input
      v-model="replaceString"
      @change="matchText"
      placeholder="字符串替换"
      title='"a>>>>b","c>>>>d"'
      style="width: 550px; padding:8px;margin-bottom: 10px;"
    />
    <br />
    <Button type="primary" @click="test" style="margin-left: 0;">
      试一下
    </Button>
    <Button type="primary" @click="distinct">
      去重
    </Button>
    <Button type="primary" @click="copy">
      复制
    </Button>
    <Button type="primary" @click="clear">
      清空
    </Button>
    <Button type="primary" @click="saveSchema" :disabled="currentSchema === 0">
      暂存
    </Button>

    <Button
      v-for="i in 5"
      :key="i"
      :type="currentSchema === i ? 'success' : 'default'"
      shape="circle"
      size="small"
      @click="loadSchema(i)"
    >
      {{ i }}
    </Button>
    <br />
    <textarea class="output" rows="10" v-model="result" placeholder="" style="width:100%; margin-top: 10px;" />
    <br />
    匹配结果: {{ count || 0 }} 条
  </div>
</template>

<script>
export default {
  name: 'TextMatch',
  data() {
    return {
      source: 'name:Tom,\nname:Jack,\nname:Stone,\n',
      result: '',
      regStr: '',
      groupIndex: '',
      replaceString: '',
      currentSchema: 0,
    };
  },
  methods: {
    matchText() {
      let val = this.source;

      if (!this.regStr) {
        this.result = '';
        return;
      }

      this.result = '正则表达式有误';
      const regex = RegExp(this.regStr, 'g');
      let result = [];

      let i = 0;
      let group = this.groupIndex || 0;

      while (true) {
        i++;
        if (i > 10000) {
          this.$Message.error('匹配结果超过了1000条，请缩小范围');
          break;
        }
        let match = regex.exec(val);
        if (!match) {
          break;
        }
        let text = '';
        if (match.length === 1) {
          text = match[0];
        } else {
          text = match[group];
        }
        if (text && text.trim().length > 0) {
          result.push(text);
        } else {
          break;
        }
      }

      if (result) {
        result = result.join('\n');
      }

      if (result && this.replaceString) {
        let replaceString = this.replaceString;
        if (replaceString.indexOf('"') === 0) {
          replaceString = '[' + replaceString + ']';
        } else {
          replaceString = '["' + replaceString + '"]';
        }
        result = this.replace(result, JSON.parse(replaceString));
      }

      if (result) {
        this.result = result;
      } else {
        this.result = '';
      }
    },

    replace(result, replaceArr) {
      for (const item of replaceArr) {
        const arr = item.split('>>>>');
        console.info(arr);
        result = result.replaceAll(arr[0], arr[1]);
      }
      return result;
    },

    test() {
      this.source = 'name:Tom,\nname:Jack,\nname:Stone,\n';
      this.regStr = 'name:(.*),';
      this.groupIndex = 1;
      this.replaceString = '';
      this.matchText(this.source);
    },

    clear() {
      this.source = '';
      this.regStr = '';
      this.result = '';
      this.groupIndex = '';
      this.replaceString = '';
      this.$refs.source_input.focus();
    },

    copy() {
      this.copyTextToClipboard(this.result);
    },

    distinct() {
      const arr = this.result.split('\n');
      for (let i = arr.length - 1; i > 0; i--) {
        const value = arr[i];
        if (arr.indexOf(value) < i) {
          arr.splice(i, 1);
        }
      }
      this.result = arr.join('\n');
    },
    loadSchema(i) {
      this.currentSchema = i;
      const str = localStorage.getItem(`text_match_schema_${i}`);
      if (str) {
        const schema = JSON.parse(str);
        this.regStr = schema.regStr;
        this.groupIndex = schema.groupIndex;
        this.replaceString = schema.replaceString;
        this.matchText();
      }
    },
    saveSchema() {
      if (this.currentSchema === 0) {
        return;
      }

      const schema = {
        regStr: this.regStr,
        groupIndex: this.groupIndex,
        replaceString: this.replaceString,
      };
      localStorage.setItem(`text_match_schema_${this.currentSchema}`, JSON.stringify(schema));
    },
  },
  computed: {
    count() {
      return this.result.split('\n').filter((x) => x !== '').length || 0;
    },
  },
  mounted() {},
  watch: {
    source(val) {
      this.matchText(val);
    },
  },
};
</script>

<style scoped>
.text-join {
  padding: 20px;
}

textarea.output {
  padding: 10px;
  margin-bottom: 15px;
}

.ivu-btn {
  margin: 0 5px;
}
</style>
