<template>
  <div class="container">
    <textarea rows="10" v-model="source" ref="source_input" placeholder="填写需要编码或解码的内容" style="width:100%;"/>
    <Button type="primary" @click="encode">
      编码
    </Button>
    <Button type="primary" @click="decode">
      解码
    </Button>
    <Button type="primary" @click="copy">
      复制
    </Button>
    <Button type="primary" @click="clear">
      清空
    </Button>
    <br>
    <br>
    <textarea class="output" rows="10" v-model="result" placeholder="" readonly style="width:100%;"/>
  </div>
</template>

<script>
export default {
  name: 'UrlDecode',
  data() {
    return {
      source: '',
      result: '',
    }
  },
  methods: {
    encode() {
      this.result = encodeURIComponent(this.source);
    },
    decode() {
      this.result = decodeURIComponent(this.source);
    },
    clear() {
      this.source = '';
      this.result = '';
      this.$refs.source_input.focus();
    },
    copy() {
      if (this.result && this.result.trim().length > 0) {
        this.copyTextToClipboard(this.result);
      }
    },
  },
  computed: {},
  mounted() {
    this.$refs.source_input.focus();
  },
}
</script>

<style scoped>

div.container {
  padding: 20px;
}

textarea {
  padding: 5px;
  margin-bottom: 15px;
}

.ivu-btn {
  margin-right: 5px;
}

</style>
