<template>
  <div class="text-join">
    <textarea
      rows="10"
      v-model="source"
      ref="source_input"
      placeholder="粘贴需要串接的文本"
      style="width:100%;padding: 5px;"
    />
    <input v-model="splitChar" placeholder="分隔符" style="width: 100px;" />
    <Button type="primary" @click="singleQuote">
      加单引号
    </Button>
    <Button type="primary" @click="doubleQuote">
      加双引号
    </Button>
    <Button type="primary" @click="removeFirst">
      去除第一个
    </Button>
    <Button type="primary" @click="addLineWrap">
      每行一个
    </Button>
    <Button type="primary" @click="removeQuote">
      去除引号
    </Button>
    <Button type="primary" @click="copy">
      复制
    </Button>
    <Button type="primary" @click="clear">
      清空
    </Button>
    <br />
    <br />
    <textarea class="output" rows="10" v-model="result" placeholder="" style="width:100%;" />
  </div>
</template>

<script>
export default {
  name: 'TextJoin',
  data() {
    return {
      source: '',
      result: '',
      splitChar: ',',
    };
  },
  methods: {
    transBase() {
      let result = this.source.replaceAll('\r', '').replaceAll('\n', ',');
      result = result.replace(/，/g, ',');
      result = result.replace(/\s/g, '');
      result = result.replace(/,+/g, ',');
      result = result.replace(/,+$/, '');
      result = result.replace(/^,/, '');

      return result;
    },

    removeQuote() {
      let result = this.result;
      result = result.replace(/'/g, '');
      result = result.replace(/"/g, '');
      this.result = result;
    },

    singleQuote() {
      let result = this.transBase();
      result = result.replace(/,/g, "','");
      result = "'" + result + "'";
      result = result.replace(/,/g, this.splitChar);
      this.result = result;
    },

    doubleQuote() {
      let result = this.transBase();
      result = result.replace(/,/g, '","');
      result = '"' + result + '"';
      result = result.replace(/,/g, this.splitChar);
      this.result = result;
    },

    removeFirst() {
      let result = this.result;
      let arr = result.split(this.splitChar);
      arr.splice(0, 1);
      result = arr.join(this.splitChar);
      result = result.replace(/^(\r\n)/g, '');
      this.result = result;
    },

    clear() {
      this.source = '';
      this.result = '';
      this.$refs.source_input.focus();
    },

    addLineWrap() {
      let result = this.result;
      result = result.replaceAll(this.splitChar, '\r\n');
      result = result.replace(/(\r\n)+/g, '\r\n');
      result = result.replace(/(\r\n)/g, `${this.splitChar}\r\n`);
      result = result.replace(/^(\r\n)/g, '');
      this.result = result;
    },

    copy() {
      this.copyTextToClipboard(this.result);
    },
  },
  computed: {},
  mounted() {},
  watch: {
    source(val) {
      let result = this.transBase(val);
      result = result.replace(/,/g, this.splitChar);
      this.result = result;
    },
    splitChar() {
      let result = this.transBase(this.source);
      result = result.replace(/,/g, this.splitChar);
      this.result = result;
    },
  },
};
</script>

<style scoped lang="scss">
.text-join {
  padding: 20px;

  button {
    margin-top: 10px;
  }

  input {
    margin-top: 10px;
    vertical-align: middle;
  }
}

textarea.output {
  padding: 5px;
  margin-bottom: 15px;
}

.ivu-btn {
  margin: 0 5px;
}
</style>
